@import "../../../styles/variables.module";
.academicService {
  .academicDetails {
    .nav {
      .navItem {
        .academicServiceType {
          color: $desc-font;
          padding: 16px 38px;
          background: $white-color;
          border-radius: 50px;
          margin: 0 10px;
          border: 1px solid transparent;
          cursor: pointer;
          -webkit-transition: all 0.15s ease-in-out;
          transition: all 0.15s ease-in-out;
          @include large_device {
            padding: 12px 15px;
            margin: 5px;
            font-size: 14px;
          }
          &.active,
          &:hover {
            background-color: $white-color;
            color: $theme-custom-color;
            border: 1px solid $secondary-color;
            -webkit-box-shadow: 0 3px 4px rgba(38, 78, 118, 0.1);
            box-shadow: 0 3px 4px rgba(38, 78, 118, 0.1);
          }
        }
      }
    }
    .tabContent {
      .tabPane {
        &:nth-child(2) {
          .serviceList {
            @include large_device {
              margin-top: 20px;
              width: 100%;
            }
            li {
              width: 33.33%;
            }
          }
        }
        .serviceList {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 90%;
          margin: 30px auto 0;
          @include medium_device {
            justify-content: flex-start;
          }
          li {
            width: calc(100% / 4);
            @include medium_device {
              width: 33.33%;
            }
            @include mobile_device {
              width: 50%;
            }
            .listLink {
              color: $theme-custom-color;
              font-size: 16px;
              line-height: 23px;
              padding: 0 0 0 20px;
              position: relative;
              margin-top: 20px;
              -webkit-transition: all 0.4s ease-in-out;
              transition: all 0.4s ease-in-out;
              @include large_device {
                margin-top: 10px;
                font-size: 14px;
                padding-left: 15px;
              }
              &::before {
                content: "";
                position: absolute;
                width: 5px;
                height: 5px;
                background-color: $secondary-color;
                top: 8px;
                border-radius: 50px;
                left: 0;
              }
              &:hover {
                color: $theme-color;
              }
            }
          }
        }
      }
    }
    .btn {
      width: 240px;
      @include mobile_device {
        width: 100%;
      }
    }
  }
}

